import {DatePipe} from "@angular/common";
import {
    AfterViewInit,
    Component,
    ElementRef,
    HostListener,
    OnInit, Renderer2,
    ViewChild,
} from "@angular/core";

import {ActivatedRoute, Router} from "@angular/router";
import {ChatService} from "src/app/services/chat.service";
import {GatewayWebsocketService} from "src/app/services/gateway-websocket/gateway-websocket.service";
import {UserService} from "src/app/services/user.service";
import {HideUserChat} from "src/app/shared/hideUserChat";
import {User} from "src/app/shared/User";
import {environment} from "src/environments/environment";
import {ConversationData} from "./conversation.model";

@Component({
    selector: "app-conversation",
    templateUrl: "./conversation.component.html",
    styleUrls: ["./conversation.component.scss"],
})
export class ConversationComponent implements OnInit, AfterViewInit {
    protected currentUserObj: User;
    public userObj: User;
    private currentUserPrenom: any;
    private abo: string;
    private currentUserAbo: string;

    @HostListener("window:popstate", ["$event"])
    onPopState(event) {
        this.hideUserChatOnMObile();
    }

    @ViewChild('modal4', { static: false }) modal4: ElementRef;
    @ViewChild('modal5', { static: false }) modal5: ElementRef;

    @ViewChild("scrollBottom") el: ElementRef;
    idServiceClient = environment.idServiceClient;
    listUsersConnected = [];
    size;
    hideUserChatValue = false;
    idAnnonce;
    contactId;
    nomUser;
    connected = false;
    image;
    isFastConnect: boolean = false;
    sexe;
    listConversation: ConversationData[];
    conversationData: ConversationData = new ConversationData();
    prenom;
    currentUserId;

    @HostListener("window:resize", ["$event"])
    onResize(event) {
        event.target.innerWidth;
        this.size = event.target.innerWidth;
    }

    constructor(
        private hideUserChat: HideUserChat,
        private route: ActivatedRoute,
        private router: Router,
        private chatService: ChatService,
        public datepipe: DatePipe,
        private user: User,
        public userService: UserService,
        private gatewayWebsocketService: GatewayWebsocketService,
        private renderer: Renderer2
    ) {
        // const routeParams = this.route.snapshot.paramMap;
        // this.contactId = Number(routeParams.get("id"));
        this.route.paramMap.subscribe((params) => {
            this.contactId = params.get("id");

            // this.nomUser = params.nomUser;
            // this.prenom = params.prenom;
            // this.sexe = params.sexe;
            // this.image = params.image;

            this.userService.getUser(this.contactId).subscribe((user: User) => {
                console.log("user abonnement", user.abonnement.type);
                this.userObj = user;
                this.nomUser = user["nom"];
                this.prenom = user["prenom"] ? user["prenom"] : user["pseudo"];
                this.abo = user.abonnement.type;
                this.sexe = user["sexe"];
                if (this.contactId == this.idServiceClient) {
                    this.image = "assets/images/customer-support.png";
                } else {
                    this.image = this.userService.getImageProfilPath(this.userObj);
                }

                this.isFastConnect = user["is_fast_connect"];
            });

            this.user.currentUser.subscribe(async (user) => {

                if (user["id"]) {
                    this.userService.getUser(user["id"]).subscribe((user: User) => {
                        console.log("local abonnement", user.abonnement.type);
                        this.currentUserId = user["id"];
                        this.currentUserObj = user;
                        this.currentUserAbo = user.abonnement.type;
                        this.currentUserPrenom = user["prenom"] ? user["prenom"] : (user["pseudo"] ? user["pseudo"] : user["nom"]);
                        this.getAnnonceId(parseInt(this.contactId));
                        this.loadConversation();
                        this.newMessageRecieved();
                    });
                }
            });
        });
    }


    ngOnInit(): void {
        this.size = window.innerWidth;
        this.gatewayWebsocketService.currentListUserConnected.subscribe((listUsersConnected) => {
            this.listUsersConnected = listUsersConnected;
            if (listUsersConnected.length > 0 && this.router.url !== '/') {
                this.loadConversation();
            }
        });

        this.hideUserChat.currentHideUserChat.subscribe((data) => {
            this.hideUserChatValue = data;
        });
    }

    getAnnonceId(contactId: number) {
        this.chatService.getAnnonceId(contactId).subscribe((id) => {
            if (id) {
                this.idAnnonce = id["id"];
            }
        });
    }

    ngAfterViewInit(): void {
        //Called after every check of the component's or directive's content.
        //Add 'implements AfterContentChecked' to the class.
        setTimeout(() => {
            this.scrollToBottom()
        }, 500);
    }

    scrollToBottom() {
        this.el.nativeElement.scrollIntoView({
            block: "start",
            inline: "start",
            behavior: "smooth",
        });
    }

    _window(): any {
        // return the global native browser window object
        return window;
    }

    isWindowActive = true;

    @HostListener('window:focus', ['$event'])
    onFocus(event) {
        this.isWindowActive = true;
        this.loadConversation();
    }

    @HostListener('window:blur', ['$event'])
    onBlur(event) {
        this.isWindowActive = false;
    }


    loadConversation(withAttachEvents = true) {
        if (this.currentUserId) {
            return this.chatService
                .loadConversation(this.currentUserId, this.contactId)
                .subscribe((data: any) => {
                    this.listConversation = data;
                    if (this.isWindowActive) {
                        this.chatService
                            .updateMessageUnread(this.currentUserId, this.contactId)
                            .subscribe(() => {
                                this.updateMessageUnread();
                            });
                    }
                    this.getUserIsConectedOrNot();
                });
        }
    }


    hideUserChatOnMObile() {
        if (this.size <= 768) {
            this.hideUserChat.changeStateHideUserChat(false);
        }
    }

    isToday(someDate) {
        const today = new Date();
        const someDateFormated = new Date(someDate);
        return (
            someDateFormated.getDate() == today.getDate() &&
            someDateFormated.getMonth() == today.getMonth() &&
            someDateFormated.getFullYear() == today.getFullYear()
        );
    }

    get isLastMessageExpress(): boolean {
        const lastConversation = this.listConversation[this.listConversation.length - 1];

        console.log("lastConversation?.express ", lastConversation );

        return lastConversation?.express === 1;
    }

    get isLastMessageExpressOui(): boolean {
        const lastConversation = this.listConversation[this.listConversation.length - 1];

        return (lastConversation?.express_resp_send === 1  &&
            lastConversation.contenu?.includes("Mon annonce est toujours d’actualité")
         );
    }

    get isLastResponseExpress(): boolean {
        const lastConversation = this.listConversation[this.listConversation.length - 1];
        return lastConversation?.express_resp_send === 1;
    }

    get isBasicLocal(): boolean {
        console.log("USER ABO", this.currentUserAbo);
        if(this.currentUserId === 1 || this.contactId) {
            console.log("SERVICE CLIENT");
            return false;
        }

        return (this.currentUserAbo === "BASIC");
    }

    get isBasicDistant(): boolean {
        console.log("ABO",this.abo);

        if(this.currentUserId === 1 || this.contactId) {
            console.log("SERVICE CLIENT");
            return false;
        }
        return (this.abo === "BASIC");
    }

    handlePopupInfoFastMessage() {
        this.renderer.addClass(this.modal5.nativeElement, 'show');
        this.renderer.setStyle(this.modal5.nativeElement, 'display', 'block');
        this.renderer.setAttribute(this.modal5.nativeElement, 'aria-modal', 'true');
        this.renderer.setAttribute(this.modal5.nativeElement, 'role', 'dialog');
    }

    handleResponseYes() {
        let title = "Votre réponse est bien partie, son destinataire pourra la lire !";
        let message = "Bonjour "+this.prenom+"\n\n" +
            "Je vous remercie pour votre message !\n" +
            "Mon annonce est toujours d’actualité et c’est avec plaisir que nous " +
            "pourrions échanger, soit par e-mail, soit par téléphone.\n" +
            "A bientôt peut-être !\n" +
            this.currentUserPrenom;

        this.openModal(title, message);
        this.addMessage({
            contenu: message,
            express_resp_send: 1
        });
    }

    handleResponseNo() {
        let title = "Votre réponse est bien partie, son destinataire pourra la lire !";
        let message = "Bonjour "+this.prenom+"\n\n" +
            "Je vous remercie pour votre message !\n" +
            "Malheureusement, je ne pourrai pas y donner une réponse favorable.\n" +
            "Je vous souhaite, tout de même, une bonne continuation sur ce site.\n" +
        this.currentUserPrenom;

        this.openModal(title, message);
        this.addMessage({
            contenu: message,
            express_resp_send: 1
        });
    }

    openModal(title, message) {
        const modalBody = this.modal4.nativeElement.querySelector('.modal-body');
        modalBody.innerHTML = `<p>${message}</p>`;

        const modalTitle = this.modal4.nativeElement.querySelector('.modal-title');
        modalTitle.innerHTML = title;

        this.renderer.addClass(this.modal4.nativeElement, 'show');
        this.renderer.setStyle(this.modal4.nativeElement, 'display', 'block');
        this.renderer.setAttribute(this.modal4.nativeElement, 'aria-modal', 'true');
        this.renderer.setAttribute(this.modal4.nativeElement, 'role', 'dialog');
    }

    closeModal() {
        this.renderer.removeClass(this.modal4.nativeElement, 'show');
        this.renderer.setStyle(this.modal4.nativeElement, 'display', 'none');
        this.renderer.removeAttribute(this.modal4.nativeElement, 'aria-modal');
        this.renderer.removeAttribute(this.modal4.nativeElement, 'role');

        this.renderer.removeClass(this.modal5.nativeElement, 'show');
        this.renderer.setStyle(this.modal5.nativeElement, 'display', 'none');
        this.renderer.removeAttribute(this.modal5.nativeElement, 'aria-modal');
        this.renderer.removeAttribute(this.modal5.nativeElement, 'role');
    }

    addMessage(conversationData: ConversationData) {
        if (conversationData.contenu) {
            const dateUtc = new Date().toUTCString();
            conversationData.date_envoi = new Date(dateUtc);

            conversationData.id_expediteur = parseInt(this.currentUserId);
            conversationData.id_destinataire = parseInt(this.contactId);

            conversationData.lu_destinataire = 0;

            conversationData.lu_expediteur = 1;
            conversationData.etat_expediteur = 0;
            conversationData.etat_destinataire = 0;

            conversationData.titre = "Message";

            conversationData.express = 0;
            conversationData.deleted_sender = 0;
            conversationData.deleted_recipient = 0;

            this.chatService.addMessage(conversationData).subscribe((_: any) => {
                if (_.success) {
                    this.sendMessage(
                        conversationData.id_expediteur,
                        conversationData.id_destinataire,
                        conversationData.contenu
                    );
                    this.conversationData.contenu = "";
                    setTimeout(() => {
                        this.scrollToBottom();
                    }, 100);
                } else {
                    window.location.href = environment.subscribeUrl + '?p=1&membre=' + _.destinataire;
                }
            });
        }
    }

    get urlSubscribe() {
        return environment.subscribeUrl + '?p=1&membre=' + this.prenom + "&follow=" + window.location;
    }

    //listening to incoming msg real time
    newMessageRecieved() {
        this.gatewayWebsocketService.listen("getMessage").subscribe((data) => {
            this.loadConversation();
        });


    }

    //sending new message realtime with
    sendMessage(senderId: number, receiverId: number, text: string) {
        this.gatewayWebsocketService.emit("sendMessage", {
            senderId,
            receiverId,
            text,
        });
    }

    //update emssage Unread real time
    updateMessageUnread() {

        this.gatewayWebsocketService.emit("updateMessageUnread", "ok");
        setTimeout(() => {
            this.scrollToBottom();
        }, 0);
    }

    getUserIsConectedOrNot() {

        this.connected = false;

        this.listUsersConnected.map((contact) => {

            if (
                contact.userId === parseInt(this.contactId) ||
                parseInt(this.contactId) === this.idServiceClient
            ) {
                this.connected = true;
            }
        });
    }

    transform(value: string, conversation: ConversationData): string {

        return (conversation.express === 1) ?
            '"' + value.trim() + '"' :
            value.replace(/\n/g, '<br/>');
    }

    protected readonly environment = environment;
}
