import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { DatePipe } from "@angular/common";
import {User} from "../shared/User";

@Injectable({
  providedIn: "root",
})
export class UserService {
  apiUrl = environment.apiUrl;
  constructor(private http: HttpClient, public datepipe: DatePipe) { }

  // loadUser(id) {
  //   return this.http.get(this.apiUrl + 'currentUser');
  // }
  loadCurrentUser() {
    return this.http.get(this.apiUrl + "currentUser", );
  }
  getUser(id) {
    return this.http.get(this.apiUrl + "getUser/" + id);
  }

  getImageProfilClass(user) {

    if(user.photo_profil != "" && user.photo_profil != null) {
     return 'avatar';
    }

    if (user.annonce.type_annonce == 'PROPOSE') {
      return 'fast';
    }

    if (user.annonce.type_annonce == 'CHERCHE') {
      return 'cherche';
    }

    if (user.annonce.type_annonce == 'CREATION') {
      return 'creation';
    }
  }

  getImageProfilPath(user) {

    if(user.photo_profil != "" && user.photo_profil != null) {
      if (environment.AMAZONAWS_FOLDER == "")
        return (
            "https://s3.eu-west-3.amazonaws.com/pic-stockage/thumbnail/" +
            user.photo_profil +
            "?" +
            this.datepipe.transform(new Date(), "yyyy/MM/dd 23:59:00", "fr")
        );
      else
        return (
            "https://s3.eu-west-3.amazonaws.com/pic-stockage/" + environment.AMAZONAWS_FOLDER + "/thumbnail/" +
            user.photo_profil +
            "?" +
            this.datepipe.transform(new Date(), "yyyy/MM/dd 23:59:00", "fr")
        );
    }

    if (user.annonce.type_annonce == 'PROPOSE') {
      return 'https://www.colocation-adulte.fr/avatar/maison.png';
    }

    if (user.sexe == 'F') {
      switch (user.annonce.type_colocataire) {
        case "INTERGENERATIONNELLE":
        case "ETUDIANT":
          return 'https://www.colocation-adulte.fr/avatar/femmeJ.png';
        case "PAPAMAMANSOLO":
        case "MAMANSOLO":
          return 'https://www.colocation-adulte.fr/avatar/maman.png';
        case "HOMOSEXUELLE":
          return 'https://www.colocation-adulte.fr/avatar/lgbtF.png';
        case "FEMME":
          return 'https://www.colocation-adulte.fr/avatar/entreF.png';
        default:
          return 'https://www.colocation-adulte.fr/avatar/femme.png';
      }
    }

    if (user.sexe == 'H') {
      switch (user.annonce.type_colocataire) {
        case "INTERGENERATIONNELLE":
        case "ETUDIANT":
          return 'https://www.colocation-adulte.fr/avatar/hommeJ.png';
        case "PAPAMAMANSOLO":
        case "PAPASOLO":
          return 'https://www.colocation-adulte.fr/avatar/papa.png';
        case "HOMOSEXUELLE":
          return 'https://www.colocation-adulte.fr/avatar/lgbtH.png';
        default:
          return 'https://www.colocation-adulte.fr/avatar/homme.png';
      }
    }

    return 'https://www.colocation-adulte.fr/avatar/homme.png';
  }


}
