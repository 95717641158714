import { Component, OnInit } from "@angular/core";
import { User } from "src/app/shared/User";
import { DatePipe } from "@angular/common";
import { UserService } from "src/app/services/user.service";
import { environment } from '../../../environments/environment';
@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit {
  host = environment.host;
  imageCurrentUser;
  nomCurrentUser;
  prenomCurrentUser;
  title;
  constructor(private user: User, public datePipe: DatePipe, private userService: UserService,) {}

  ngOnInit(): void {
    this.user.currentUser.subscribe((user) => {
      let sexe = user["sexe"];

      let image = user["image"];

      this.imageCurrentUser = this.userService.getImageProfilPath(user);
      this.nomCurrentUser = user["nom"];
      this.prenomCurrentUser = user["prenom"] ? user["prenom"] : "";
      this.title = this.prenomCurrentUser + " " + this.nomCurrentUser;
    });
  }
  
}
